
.ql-toolbar {
	border-radius: 10px 10px 0 0;
}

.ql-container {
	border-radius: 0 0 10px 10px;
}
.ql-editor {
	font-family: $base-font-family;
	font-size: 1rem;

	h1 {
		font-family: $base-font-family;
		font-size: 1.2rem !important;
		color: #252525;
	}

	p {
		margin-bottom: 10px;
		color: #252525;
	}

	ol, ul {
		color: #252525;
		font-size: 1rem;
		margin-bottom: 10px;
	}
}